import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {AutoFormComponent} from "./pages/auto-form/auto-form.component";
import {HouseFormComponent} from "./pages/house-form/house-form.component";
import {ProductAutoComponent} from "./pages/product-auto/product-auto.component";
import {ProductBusinessComponent} from "./pages/product-business/product-business.component";
import {ProductCommercialComponent} from "./pages/product-commercial/product-commercial.component";
import {ProductHouseComponent} from "./pages/product-house/product-house.component";
import {ProductLifeComponent} from "./pages/product-life/product-life.component";
import {ProductMexicoComponent} from "./pages/product-mexico/product-mexico.component";
import {ProductMotorcicleComponent} from "./pages/product-motorcicle/product-motorcicle.component";
import {ProductRecreationalComponent} from "./pages/product-recreational/product-recreational.component";
import {ServiceDmvComponent} from "./pages/service-dmv/service-dmv.component";
import {ServiceIncometaxComponent} from "./pages/service-incometax/service-incometax.component";
import {ServiceNotaryComponent} from "./pages/service-notary/service-notary.component";
import {ServiceInsuranceComponent} from "./pages/service-insurance/service-insurance.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'auto', component: AutoFormComponent},
  {path: 'house', component: HouseFormComponent},
  {path: 'product-auto', component: ProductAutoComponent},
  {path: 'product-commercial', component: ProductCommercialComponent},
  {path: 'product-business', component: ProductBusinessComponent},
  {path: 'product-house', component: ProductHouseComponent},
  {path: 'product-life', component: ProductLifeComponent},
  {path: 'product-mexico', component: ProductMexicoComponent},
  {path: 'product-motorcicle', component: ProductMotorcicleComponent},
  {path: 'product-recreational', component: ProductRecreationalComponent},
  {path: 'service-dmv', component: ServiceDmvComponent},
  {path: 'service-income', component: ServiceIncometaxComponent},
  {path: 'service-notary', component: ServiceNotaryComponent},
  {path: 'service-insurance', component: ServiceInsuranceComponent},
  {  path: '**', redirectTo: 'notfound' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
