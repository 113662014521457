import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-business',
  templateUrl: './product-business.component.html',
  styleUrls: ['./product-business.component.css']
})
export class ProductBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
