import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-notary',
  templateUrl: './service-notary.component.html',
  styleUrls: ['./service-notary.component.css']
})
export class ServiceNotaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
