import { Component, OnInit } from '@angular/core';
import {EmailServiceService} from "../../services/email-service.service";

@Component({
  selector: 'app-house-form',
  templateUrl: './house-form.component.html',
  styleUrls: ['./house-form.component.css']
})
export class HouseFormComponent implements OnInit {
  start = true;
  step1 = false;
  end = false;
  progress = true;
  progressText = 'Step 2 - Get Personalized Offers...';
  progressValue = 70;
  type = '';
  year = '';
  homeType = '';
  stories = '';
  bedrooms = '';
  bathrooms = '';
  garage = '';
  address1 = '';
  address2 = '';
  state = '';
  city = '';
  zip = '';
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  deductible = '';
  states = [

  ];
  deductibles = [
   '1 Percent','2 Percent','$100','$250','$500','$1000'
  ];
  ammount = '';
  ammounts = [

    '$100.000','$300.000 (most common)','$500.000','$1.000.000 umbrella liability'
  ];
  discount = {
    None: true,
    Deadbolts: false,
    SmokeAlarms: false
  };
  claims = {
    None:true,
    Theft:false,
    Weather: false
  };
  security = '';
  securityOptions = [
    'None',
    'Monitored',
    'Unmonitored',
    'Not sure'
  ];
  garages = [
    'Attached - 1 Car',
    'Attached - 2 Car',
    'Attached - 3 Car',
    'Attached - Carport'
  ];
  storiesOptions = [
    '1 Story',
    '2 Story',
    '3 Story',
    '4 or More Stories'
  ];
  homeTypes = [
    'Single Family Home',
    'Apartment','Duplex',
    'Condominium',
    'Townhome',
    'Manufactured/Mobile Home',
    'Other'
  ];
  years = [
    '2010-2020','2000-2009','1990-1999','Before 1990'
  ];
  types = [
    'Homeowners - I own and need content coverage',
    'Condo - I own and need content coverage',
    'Townhome - I own and need content coverage',
    'Landlord - I own but renting to another party'
  ];

  constructor(private emailService: EmailServiceService) { }

  ngOnInit(): void {
  }

  OnSubmit() {
    if (this.phoneNumber !== '' && this.email !== '') {
      this.sendMessage();
    }
    this.progress = true;
    this.progressValue = 100;
    this.progressText = 'Completed!';
    this.step1 = false;
    this.end = true;
  }

  sendMessage() {
    let data =
      '<h2 style="font-weight: bold;">New Home Insurance Request</h2>' +
      'Insurance Type: <strong>' + this.type + '</strong><br />' +
      'Year of build: <strong>' + this.year + '</strong><br />' +
      'Building Type: <strong>' + this.homeType + '</strong><br />' +
      'Stories: <strong>' + this.stories + '</strong><br />' +
      'Stories: <strong>' + this.stories + '</strong><br />' +
      'Bedrooms: <strong>' + this.bedrooms + '</strong><br />' +
      'Garage <strong>' + this.garage + '</strong><br />' +
      'Potencial Discount <strong>' + this.discount + '</strong><br />' +
      'Security System <strong>' + this.security + '</strong><br />' +
      'Past Claims <strong>' + this.claims + '</strong><br />' +
      'Liability Protection Ammount <strong>' + this.ammount + '</strong><br />' +
      'Deductible <strong>' + this.deductible + '</strong><br />' +
      'Phone: <strong><a href="tel:' + this.phoneNumber + '">' + this.phoneNumber + '</a></strong><br />' +
      'Email: <strong><a href="mailto:' + this.email + '">' + this.email + '</a></strong><br />' +
      'Address: <strong>' + this.address1 + '|' + this.address2 +'</strong><br />' +
      'Zip Code: <strong>' + this.zip + '</strong><br />' +
      'State: <strong>' + this.state + '</strong><br />' +
      'City <strong>' + this.city + '</strong><br />' +
      'First Name <strong>' + this.firstName + '</strong><br />' +
      'Last Name <strong>' + this.lastName + '</strong><br />' +
      '</em></strong><br /><br /><br />';
    setTimeout(() => {
      this.emailService.sendEmail('New Request', data);
    }, 5000);  }
}
