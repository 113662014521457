import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-auto',
  templateUrl: './product-auto.component.html',
  styleUrls: ['./product-auto.component.css']
})
export class ProductAutoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
