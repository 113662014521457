import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-insurance',
  templateUrl: './service-insurance.component.html',
  styleUrls: ['./service-insurance.component.css']
})
export class ServiceInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
