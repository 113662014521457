import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-form-top',
  templateUrl: './form-top.component.html',
  styleUrls: ['./form-top.component.css']
})
export class FormTopComponent implements OnInit {
  hamburgerOpen = false;


  constructor(private translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  translateFunction(lang: any) {
    this.translate.setDefaultLang(lang);

  }
  scrollTo(id: string){
    this.router.navigate(['/']);
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView(true)
    }, 2000);
  }

}
