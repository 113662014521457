import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {Vehicle} from "../../services/Vehicle";
import {EmailServiceService} from "../../services/email-service.service";

@Component({
  selector: 'app-auto-form',
  templateUrl: './auto-form.component.html',
  styleUrls: ['./auto-form.component.css'],
  providers: [MessageService]
})
export class AutoFormComponent implements OnInit {
  vehicle1: any = {
    year: '',
    maker: '',
    model: '',
    use: ''
  };
  vehicle2: any = {
    year: '',
    maker: '',
    model: '',
    use: ''
  };
  vehicle3: any = {
    year: '',
    maker: '',
    model: '',
    use: ''
  };
  vehicle4: any = {
    year: '',
    maker: '',
    model: '',
    use: ''
  };
  thisYear = (new Date()).getFullYear();
  progress = 0;
  vehicles = 1;
  counter = 1;
  zipCode = '';
  year = this.thisYear;
  model = '';
  primaryUse = 'Personal';
  maker = 'BMW';
  comprehensiveAmmount = 'None';
  collisionAmmount = 'None';
  driversNumber = 'One';
  birthDay = '';
  birthMonth = '';
  birthYear = '';
  maritalStatus = 'Married';
  address = '';
  name = '';
  email = '';
  phone = '';
  tickets = 'No Incidents';
  gender = 'male';
  modelList: any[] = [];
  makers = [
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrollet',
    'Chrysler',
    'Dodge',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Jaguar',
    'Jeep',
    'Kia',
    'Lexus',
    'Lincoln',
    'Mercedes-Benz',
    'MINI',
    'Polestar',
    'Subaru',
    'Toyota',
    'Volkswagen',
    'Volvo'
  ];
  modelsBMW = ['2 Series','3 Series','5 Series','7 Series','8 Series','M2','M5','M8','X1','X2','X3 M','X4 M','X5','X5 M','X6','X6 M','Z4'];
  modelsBuick = ['Encore','Encore GX'];
  modelsCadillac = ['Escalade', 'Escalade ESV', 'XT4','XT5','XT6'];
  modelsChevrollet = ['Camaro','Colorado','Spark','Suburban','Tahoe','Trailblazer','Trax'];
  modelsChrysler = ['Voyager'];
  modelsDodge = ['Challenger','Charger'];
  modelsFord = ['EcoSport','E-Series Chassis','Explorer','Explorer Hybrid','F-150','Mustang Mach-E','Transit Connect Cargo','Transit Connect Wagon'];
  modelsGenesis = ['G70'];
  modelsGMC = ['Acadia','Canyon','Yukon','Yukon XL'];
  modelsHonda = ['Insight','Pilot'];
  modelsHyundai = ['Kona','Kona EV', 'Palisade','Tucson','Veloster'];
  modelsJaguar = ['F-TYPE'];
  modelsJeep = ['Wrangler','Wrangler Unlimited'];
  modelsKia = ['K5','Sedona','Seltos','Soul','Soul EV','Sportage','Telluride'];
  modelsLexus = ['LC 500','LC 500h'];
  modelsLincoln = ['Aviator'];
  modelsMercedesBenz = ['A-Class','AMG GT','C-Class','CLA','CLS','E-Class','EQC','G-Class','GLA','GLB','GLC','GLE','GLS','S-Class'];
  modelsMINI = ['Convertible','Hardtop 2 Door','Hardtop 4 Door'];
  modelsPolestar = ['1','2'];
  modelsSubaru = ['Ascent','Crosstrek'];
  modelsToyota = ['Corolla','Corolla Hatchback','Corolla Hybrid','GR Supra','Prius','RAV4 Prime'];
  modelsVolkswagen = ['Atlas'];
  modelsVolvo = ['S60','S90','V60','V60 Cross Country','V90','V90 Cross Country','XC40','XC60','XC90'];
  uses = [
    'Personal',
    'Commute',
    'Artisan',
    'Business'
  ];
  ammounts = [
    'None',
    '$250',
    '$500',
    '$1000'
  ];
  drivers = [
    'One',
    'Two',
  ];
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  maritalStatuses = [
    'Married', 'Single', 'Divorced', 'Widowed', 'Domestic Partner'
  ];
  ticketsOptions = [
    'No Incidents','Ticket - Other Minor','Ticket - DUI/DWI','Accident','License Suspension'
  ];
  days = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24',
  '25','26','27','28','29','30','31'];
  years: any = [];
  start = true;
  step1 = false;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  step6 = false;
  step7 = false;
  step8 = false;
  step9 = false;
  step10 = false;
  step11 = false;
  step12 = false;
  step13 = false;
  step14 = false;
  step15 = false;
  step16 = false;
  end = false;
  data = [];

  constructor(private messageService: MessageService, private emailService: EmailServiceService) { }

  ngOnInit(): void {
    for (var i=80;i>=16;i--){
      this.years.push(((new Date()).getFullYear() - i).toString())
    }
  }
  OnStart(){
    if (this.zipCode != ''){
      this.start = false;
      this.step1 = true;
      this.progress = 3;
    } else {
      this.messageService.add({severity:'warn', summary: 'Wrong Data', detail: 'Please provide a correct zip code value', life: 3000});
    }
  }
  OnNumberSelection(element: Vehicle){
    this.progress = 6;
    this.step1 = false;
    this.step2 = true;
    // for(var i = this.vehicles;i>=1;i--) {
    //   this.data.push({...element})
    // }
  }
  OnYearSelection(){
    this.progress+=2;
    this.step2 = false;
    this.step3 = true;
  }
  OnMakerSelection(){
    this.progress+=2;
    this.step3 = false;
    this.step4 = true;
    if (this.maker === 'BMW') {
      this.modelList = this.modelsBMW;
    }
    if (this.maker === 'Buick') {
      this.modelList = this.modelsBuick;
    }
    if (this.maker === 'Cadillac') {
      this.modelList = this.modelsCadillac;
    }
    if (this.maker === 'Chevrollet') {
      this.modelList = this.modelsChevrollet;
    }
    if (this.maker === 'Chrysler') {
      this.modelList = this.modelsChrysler;
    }
    if (this.maker === 'Dodge') {
      this.modelList = this.modelsDodge;
    }
    if (this.maker === 'Ford') {
      this.modelList = this.modelsFord;
    }
    if (this.maker === 'Genesis') {
      this.modelList = this.modelsGenesis;
    }
    if (this.maker === 'GMC') {
      this.modelList = this.modelsGMC;
    }
    if (this.maker === 'Honda') {
      this.modelList = this.modelsHonda;
    }
    if (this.maker === 'Hyundai') {
      this.modelList = this.modelsHyundai;
    }
    if (this.maker === 'Jaguar') {
      this.modelList = this.modelsJaguar;
    }
    if (this.maker === 'Jeep') {
      this.modelList = this.modelsJeep;
    }
    if (this.maker === 'Kia') {
      this.modelList = this.modelsKia;
    }
    if (this.maker === 'Lexus') {
      this.modelList = this.modelsLexus;
    }
    if (this.maker === 'Lincoln') {
      this.modelList = this.modelsLincoln;
    }
    if (this.maker === 'Mercedes-Benz') {
      this.modelList = this.modelsMercedesBenz;
    }
    if (this.maker === 'MINI') {
      this.modelList = this.modelsMINI;
    }
    if (this.maker === 'Polestar') {
      this.modelList = this.modelsPolestar;
    }
    if (this.maker === 'Subaru') {
      this.modelList = this.modelsSubaru;
    }
    if (this.maker === 'Toyota') {
      this.modelList = this.modelsToyota;
    }
    if (this.maker === 'Volkswagen') {
      this.modelList = this.modelsVolkswagen;
    }
    if (this.maker === 'Volvo') {
      this.modelList = this.modelsVolvo;
    }
  }
  OnModelSelection(){
    if (this.model != ''){
      this.progress += 2;
      this.step4 = false;
      this.step5 = true;
    } else {
      this.messageService.add({severity:'warn', summary: 'Wrong Data', detail: 'Please provide a correct model', life: 3000});
    }

  }
  OnUseSelection(){
    this.progress += 2;
    if (this.counter === 1){
        this.vehicle1.maker = this.maker;
        this.vehicle1.model = this.model;
        this.vehicle1.year = this.year;
        this.vehicle1.use = this.primaryUse;
        console.log(this.vehicle1);
      }
      if (this.counter === 2){
        this.vehicle2.maker = this.maker;
        this.vehicle2.model = this.model;
        this.vehicle2.year = this.year;
        this.vehicle2.use = this.primaryUse;
      }
      if (this.counter === 3){
        this.vehicle3.maker = this.maker;
        this.vehicle3.model = this.model;
        this.vehicle3.year = this.year;
        this.vehicle3.use = this.primaryUse;
      }
      if (this.counter === 4){
        this.vehicle4.maker = this.maker;
        this.vehicle4.model = this.model;
        this.vehicle4.year = this.year;
        this.vehicle4.use = this.primaryUse;
      }

    if (this.counter == this.vehicles){
      this.step5 = false;
      this.step6 = true;
    } else {
      this.step5 = false;
      this.step2 = true;
      this.counter = this.counter +1;
      console.log(this.counter);
    }
  }
  OnComprehensiveAmmountSelection(){
    this.progress = 50;
    this.step6 = false;
    this.step7 = true;
  }
  OnCollisionAmmountSelection(){
    this.progress = 55;
    this.step7 = false;
    this.step8 = true;
  }
  OnDriversNumberSelection(){
    this.progress = 60;
    this.step8 = false;
    this.step9 = true;
  }
  OnDriverAgeSelection(){
    if (this.birthDay != '' && this.birthMonth != '' && this.birthYear != ''){
      this.progress = 65;
      this.step9 = false;
      this.step10 = true;
    } else {
      this.messageService.add({severity:'warn', summary: 'Wrong Data', detail: 'Please provide a correct birth date', life: 3000});
    }

  }
  OnGenderSelection(){
    this.progress = 70;
    this.step10 = false;
    this.step11 = true;
  }
  OnMaritalStatusSelection(){
    this.progress = 75;
    this.step11 = false;
    this.step12 = true;
  }
  OnTicketSelection(){
    this.progress = 80;
    this.step12 = false;
    this.step13 = true;
  }
  OnAddressSelection(){
    if (this.address != ''){
      this.progress = 85;
      this.step13 = false;
      this.step14 = true;
    } else {
      this.messageService.add({severity:'warn', summary: 'Wrong Data', detail: 'Please provide a correct address', life: 3000});
    }

  }
  OnNameSelection(){
    if (this.name != ''){
      this.progress = 90;
      this.step14 = false;
      this.step15 = true;
    } else {
      this.messageService.add({severity:'warn', summary: 'Wrong Data', detail: 'Please provide your full name', life: 3000});
    }

  }
  OnEmailSelection(){
    if (this.email != ''){
      this.progress = 95;
      this.step15 = false;
      this.step16 = true;
    } else {
      this.messageService.add({severity:'warn', summary: 'Wrong Data', detail: 'Please provide your email', life: 3000});
    }

  }
  OnPhoneSelection(){
    if (this.phone != ''){
      this.progress = 100;
      let data =
        '<body style="margin: 0; padding: 0">' +
        'Subject <strong>Vehicle Assurance</strong><br/>' +
        'Client Name: <strong>' + this.name + '</strong><br />' +
        'Phone: <strong><a href="tel:' + this.phone + '">' + this.phone + '</a></strong><br />' +
        'Email: <strong><a href="mailto:' + this.email + '">' + this.email + '</a></strong><br />' +
        'Address: <strong>' + this.address + '</strong><br />' +
        'Zip Code: <strong>' + this.zipCode + '</strong><br />' +
        'Vehicles(' + this.vehicles + '): <br />' +
        '1:<br>' +
        'Year:<strong>'+ this.vehicle1.year +'</strong><br>' +
        'Maker:<strong>'+ this.vehicle1.maker +'</strong><br>' +
        'Model:<strong>'+ this.vehicle1.model +'</strong><br>' +
        'Priary Usage:<strong>'+ this.vehicle1.use +'</strong><br>';
      if (this.vehicles > 1) {
        data +=  '2:<br>' +
          'Year:<strong>'+ this.vehicle2.year +'</strong><br>' +
          'Maker:<strong>'+ this.vehicle2.maker +'</strong><br>' +
          'Model:<strong>'+ this.vehicle2.model +'</strong><br>' +
          'Priary Usage:<strong>'+ this.vehicle2.use +'</strong><br>';
      }
      if (this.vehicles > 2) {
        data +=  '3:<br>' +
          'Year:<strong>'+ this.vehicle3.year +'</strong><br>' +
          'Maker:<strong>'+ this.vehicle3.maker +'</strong><br>' +
          'Model:<strong>'+ this.vehicle3.model +'</strong><br>' +
          'Priary Usage:<strong>'+ this.vehicle3.use +'</strong><br>';
      }
      if (this.vehicles === 4) {
        data +=  '4:<br>' +
          'Year:<strong>'+ this.vehicle4.year +'</strong><br>' +
          'Maker:<strong>'+ this.vehicle4.maker +'</strong><br>' +
          'Model:<strong>'+ this.vehicle4.model +'</strong><br>' +
          'Priary Usage:<strong>'+ this.vehicle4.use +'</strong><br>';
      }

        data += 'Comprehensive Deductible Ammount: <strong>' + this.comprehensiveAmmount + '</strong><br />' +
          'Collision Deductible Amount: <strong>' + this.collisionAmmount + '</strong><br />' +
          'Number od Drivers: <strong>' + this.driversNumber + '</strong><br />' +
          'Principal Driver Birthdate: <strong>' + this.birthDay + '|' + this.birthMonth + '|' + this.birthYear + '</strong><br/>' +
          'Principal Driver Gender: <strong>' + this.gender + '</strong><br />' +
          'Principal Driver Marital Status: <strong>' + this.maritalStatus + '</strong><br />' +
          'Previous Incidents: <strong>' + this.tickets + '</strong><br />';
      setTimeout(() => {
        this.emailService.sendEmail('New Request', data);
      }, 5000);
      this.step16 = false;
      this.end = true;
    } else {
      this.messageService.add({severity:'warn', summary: 'Wrong Data', detail: 'Please provide your phone number', life: 3000});
    }
  }
  reset(){
    this.zipCode = '';
    this.end = false;
    this.start = true;
  }

}
