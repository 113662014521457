import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from  'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Location, LocationStrategy } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class EmailServiceService {

  constructor(private httpClient: HttpClient,private location: Location, private locationStrategy: LocationStrategy) { }

  sendEmail(subject: any, message:any): Promise<any> {
    const url = '/assets/testEmail/sendEmail.php?' + 'subject=' + subject + '&message=' + message;
    return this.httpClient.get<any>(url).toPromise();
  }
}
