import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NotifierModule } from 'angular-notifier';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
//Prime
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {SlideMenuModule} from 'primeng/slidemenu';
import {CarouselModule} from 'primeng/carousel';
import {GalleriaModule} from 'primeng/galleria';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {RippleModule} from 'primeng/ripple';
import {TooltipModule} from "primeng/tooltip";
import {EditorModule} from 'primeng/editor';
import {StepsModule} from 'primeng/steps';
import {ListboxModule} from 'primeng/listbox';
import {DataViewModule} from 'primeng/dataview';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
// Traducciones
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// App
import { HomeComponent } from './pages/home/home.component';
import { AutoFormComponent } from './pages/auto-form/auto-form.component';
import { FormTopComponent } from './partials/form-top/form-top.component';
import { HouseFormComponent } from './pages/house-form/house-form.component';
import { ProductAutoComponent } from './pages/product-auto/product-auto.component';
import { ProductLifeComponent } from './pages/product-life/product-life.component';
import { ProductBusinessComponent } from './pages/product-business/product-business.component';
import { ProductHouseComponent } from './pages/product-house/product-house.component';
import { ProductMotorcicleComponent } from './pages/product-motorcicle/product-motorcicle.component';
import { ProductRecreationalComponent } from './pages/product-recreational/product-recreational.component';
import { ProductMexicoComponent } from './pages/product-mexico/product-mexico.component';
import { ProductCommercialComponent } from './pages/product-commercial/product-commercial.component';
import { ServiceDmvComponent } from './pages/service-dmv/service-dmv.component';
import { ServiceIncometaxComponent } from './pages/service-incometax/service-incometax.component';
import { ServiceNotaryComponent } from './pages/service-notary/service-notary.component';
import { ServiceInsuranceComponent } from './pages/service-insurance/service-insurance.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HomeComponent,
    AutoFormComponent,
    FormTopComponent,
    HouseFormComponent,
    ProductAutoComponent,
    ProductLifeComponent,
    ProductBusinessComponent,
    ProductHouseComponent,
    ProductMotorcicleComponent,
    ProductRecreationalComponent,
    ProductMexicoComponent,
    ProductCommercialComponent,
    ServiceDmvComponent,
    ServiceIncometaxComponent,
    ServiceNotaryComponent,
    ServiceInsuranceComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SidebarModule,
    SelectButtonModule,
    BrowserModule,
    ProgressSpinnerModule,
    VirtualScrollerModule,
    ScrollPanelModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    InputTextModule,
    MultiSelectModule,
    DropdownModule,
    SlideMenuModule,
    CarouselModule,
    GalleriaModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckboxModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    ContextMenuModule,
    DialogModule,
    ProgressBarModule,
    FileUploadModule,
    InputTextareaModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    CardModule,
    RippleModule,
    BsDropdownModule.forRoot(),
    TooltipModule,
    EditorModule,
    StepsModule,
    DataViewModule,
    ListboxModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12,
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10,
        },
      },}),
  ],
  providers: [
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
